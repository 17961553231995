body {
  margin: 0;
  height: 80vh;
  background-color: #333333;
  font-family: monospace;
}

#container {
  border: #222 dashed 2px;
  width: max-content;
  margin: auto;
  position: relative;
}

#titlebar {
  display: flex;
  color: #aaa;
  margin: 0.5rem;
  font-size: 2rem;
  user-select: none;
}

#titlebar > #instructions {
  flex-grow: 1;
}

#titlebar > div > .value {
  color: orange;
  margin-left: 2rem;
}

@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.1); }
  100% { transform: scale(1); } 
} 

#status {
  display: none;
  font-size: 2rem;
  position: absolute;
  color: orange;
  top: 50%;
  left: 0;
  right: 0;
  text-align: center;
  animation: pulse 2s infinite;
  pointer-events: none;
}

#status.visible {
  display: block;
}
